import type {FieldValues, UseFormReturn} from "react-hook-form"

type FormProps<T extends FieldValues> = Omit<React.HTMLAttributes<HTMLFormElement>, "onSubmit"> & {
  form: UseFormReturn<T>
  onSubmit: (values: T) => void
}

export const Form = <T extends FieldValues>({form, onSubmit, children, ...props}: FormProps<T>) => (
  <form
    {...props}
    onSubmit={(values) => {
      form.clearErrors()
      form.handleSubmit(onSubmit)(values)
    }}
  >
    {children}
  </form>
)
