import type {Action} from "~/flux/ActionTypes"
import {Store} from "~/flux/Store"
import type {UserPrivate} from "~/records/UserRecord"
import * as RouterUtils from "~/utils/RouterUtils"

export type LoginStates = "default" | "mfa"

const TOKEN_KEY = "token"
const USER_ID_KEY = "userId"

type State = {
  loginState: LoginStates
  mfaTicket: string | null
  token: string | null
  userId: string | null
}

const initialState: State = {
  loginState: "default",
  mfaTicket: null,
  token: localStorage.getItem(TOKEN_KEY),
  userId: localStorage.getItem(USER_ID_KEY),
}

class AuthenticationStore extends Store<State> {
  constructor() {
    super(initialState)
  }

  handleAction(action: Action) {
    switch (action.type) {
      case "CONNECTION_OPEN":
        return this.handleConnectionOpen(action)
      case "AUTH_SESSION_CHANGE":
        return this.handleAuthSessionChange(action)
      case "CONNECTION_CLOSED":
        return this.handleConnectionClosed(action)
      case "SESSION_START":
        return this.handleSessionStart(action)
      case "MFA_TICKET_SET":
        return this.handleMfaTicketSet(action)
      case "MFA_TICKET_CLEAR":
        return this.handleMfaTicketClear()
      case "LOGOUT":
        return this.handleLogout()
      default:
        return false
    }
  }

  getId(): string {
    return this.state.userId!
  }

  getToken(): string {
    return this.state.token!
  }

  getMfaTicket(): string {
    return this.state.mfaTicket!
  }

  private handleConnectionOpen({user}: {user: UserPrivate}) {
    localStorage.setItem(USER_ID_KEY, user.id)
    this.setState((prevState) => ({...prevState, userId: user.id}))
  }

  private handleAuthSessionChange({token}: {token: string}) {
    localStorage.setItem(TOKEN_KEY, token)
    this.setState((prevState) => ({...prevState, token}))
  }

  private handleConnectionClosed({code}: {code: number}) {
    if (code === 4004) {
      this.handleLogout()
    }
  }

  private handleSessionStart({token}: {token: string}) {
    localStorage.setItem(TOKEN_KEY, token)
    this.setState((prevState) => ({...prevState, loginState: "default", token}))
  }

  private handleMfaTicketSet({ticket}: {ticket: string}) {
    this.setState((prevState) => ({...prevState, loginState: "mfa", mfaTicket: ticket}))
  }

  private handleMfaTicketClear() {
    this.setState((prevState) => ({...prevState, loginState: "default", mfaTicket: null}))
  }

  private handleLogout() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_ID_KEY)
    this.setState({
      loginState: "default",
      mfaTicket: null,
      token: null,
      userId: null,
    })
    RouterUtils.replaceWith("/login")
  }
}

export default new AuthenticationStore()
