import {ExponentialBackoff} from "~/lib/ExponentialBackoff"
import AuthenticationStore from "~/stores/AuthenticationStore"

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE"

type Headers = Record<string, string>

type RequestConfig = {
  url: string
  query?: Record<string, any> | string
  body?: unknown
  headers?: Headers
  retries?: number
  retryCount?: number
  backoff?: ExponentialBackoff
}

type Response<T = unknown> = {
  status: number
  headers: Headers
  body: T
  ok: boolean
}

type ErrorResponse = {
  ok: false
  error: Error
}

type ResponseCallback<T> = (response: Response<T> | ErrorResponse) => void

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class HttpClient {
  private static normalizeUrl(url: string): string {
    if (!url.startsWith("/")) return url
    return `${window.ENV.API_ENDPOINT}/v${window.ENV.API_VERSION}${url}`
  }

  private static createQueryString(query?: Record<string, any> | string): string {
    if (!query) return ""
    return new URLSearchParams(query).toString()
  }

  private static buildUrl(url: string, query?: Record<string, any> | string): string {
    const normalizedUrl = HttpClient.normalizeUrl(url)
    const queryString = HttpClient.createQueryString(query)
    return queryString ? `${normalizedUrl}?${queryString}` : normalizedUrl
  }

  private static createHeaders(config: RequestConfig): Headers {
    const headers: Headers = {...config.headers}

    if (config.url.startsWith("/")) {
      const token = AuthenticationStore.getToken()
      if (token) headers.Authorization = token
    }

    if (config.body) {
      headers["Content-Type"] = "application/json"
    }

    if (config.retryCount) {
      headers["X-Failed-Requests"] = config.retryCount.toString()
    }

    return headers
  }

  private static async makeRequest<T>(
    method: Method,
    config: RequestConfig,
    resolve: (response: Response<T>) => void,
    reject: (response: Response | Error) => void,
    callback?: ResponseCallback<T>,
  ): Promise<void> {
    try {
      const response = await fetch(HttpClient.buildUrl(config.url, config.query), {
        method,
        headers: HttpClient.createHeaders(config),
        body: config.body ? JSON.stringify(config.body) : undefined,
      })

      const result: Response<T> = {
        ok: response.ok,
        status: response.status,
        headers: Object.fromEntries(response.headers.entries()),
        body: response.status === 204 ? (null as T) : await response.json(),
      }

      if (HttpClient.shouldRetry(result.status) && config.retries && config.retries > 0) {
        await HttpClient.handleRetry(method, config, resolve, reject, callback)
        return
      }

      if (response.ok) {
        resolve(result)
      } else {
        reject(result)
      }

      callback?.(result)
    } catch (error) {
      if (config.retries && config.retries > 0) {
        await HttpClient.handleRetry(method, config, resolve, reject, callback)
        return
      }

      const errorResponse: ErrorResponse = {
        ok: false,
        error: error as Error,
      }

      reject(error as Error)
      callback?.(errorResponse)
    }
  }

  private static shouldRetry(status: number): boolean {
    return status >= 500 || status === 429
  }

  private static async handleRetry<T>(
    method: Method,
    config: RequestConfig,
    resolve: (response: Response<T>) => void,
    reject: (response: Response | Error) => void,
    callback?: ResponseCallback<T>,
  ): Promise<void> {
    config.backoff = config.backoff || new ExponentialBackoff({initialDelay: 1000, maxDelay: 10000})
    config.retryCount = (config.retryCount || 0) + 1
    config.retries = config.retries! - 1

    const delay = config.backoff.next()
    if (delay === -1) {
      throw new Error("Max retry attempts reached")
    }

    await new Promise((resolve) => setTimeout(resolve, delay))
    await HttpClient.makeRequest(method, config, resolve, reject, callback)
  }

  static request<T>(
    method: Method,
    options: string | RequestConfig,
    callback?: ResponseCallback<T>,
  ): Promise<Response<T>> {
    const config: RequestConfig = typeof options === "string" ? {url: options} : options

    return new Promise((resolve, reject) => {
      HttpClient.makeRequest(method, config, resolve, reject, callback)
    })
  }
}

export const get = <T>(opts: string | RequestConfig, callback?: ResponseCallback<T>) =>
  HttpClient.request<T>("GET", opts, callback)

export const post = <T>(opts: string | RequestConfig, callback?: ResponseCallback<T>) =>
  HttpClient.request<T>("POST", opts, callback)

export const put = <T>(opts: string | RequestConfig, callback?: ResponseCallback<T>) =>
  HttpClient.request<T>("PUT", opts, callback)

export const patch = <T>(opts: string | RequestConfig, callback?: ResponseCallback<T>) =>
  HttpClient.request<T>("PATCH", opts, callback)

export const del = <T>(opts: string | RequestConfig, callback?: ResponseCallback<T>) =>
  HttpClient.request<T>("DELETE", opts, callback)

export type {RequestConfig as HttpRequest}
export type {Response as HttpResponse}
