import clsx from "clsx"
import React from "react"
import styles from "~/components/uikit/Button/Button.module.css"

export type ButtonProps = {
  children?: React.ReactNode
  className?: string
  contentClassName?: string
  disabled?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onClick?(): void
  small?: boolean
  submitting?: boolean
  type?: "button" | "submit"
  variant?: "brand" | "primary" | "secondary" | "danger" | "danger-outline" | "ghost" | "inverted"
  fitContainer?: boolean
  fitContent?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    className,
    contentClassName,
    disabled,
    leftIcon,
    rightIcon,
    onClick,
    small,
    submitting,
    type = "button",
    variant = "brand",
    fitContainer = true,
    fitContent = false,
  } = props

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (submitting) {
      event.preventDefault()
      return
    }
    onClick?.()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (submitting) {
      event.preventDefault()
      return
    }
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault()
      onClick?.()
    }
  }

  return (
    <div className={className}>
      <button
        ref={ref}
        className={clsx(
          styles.button,
          styles[variant],
          {
            [styles.small]: small,
            [styles.submitting]: submitting,
            [styles.fitContainer]: fitContainer,
            [styles.fitContent]: fitContent,
          },
          className,
        )}
        disabled={disabled}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        type={type}
      >
        <div className={clsx(styles.content, contentClassName)}>
          <div className={styles.grid}>
            <div className={clsx(styles.iconWrapper, {[styles.hidden]: submitting})}>
              {leftIcon}
              {children}
              {rightIcon}
            </div>
            <div className={clsx(styles.spinnerWrapper, {[styles.hidden]: !submitting})}>
              <span className={styles.spinner}>
                <span className={styles.spinnerInner}>
                  <span className={styles.spinnerItem} />
                  <span className={styles.spinnerItem} />
                  <span className={styles.spinnerItem} />
                </span>
              </span>
            </div>
          </div>
        </div>
      </button>
    </div>
  )
})

Button.displayName = "Button"
