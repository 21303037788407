const INITIAL_BACKOFF = 1000 // 1 second
const MAX_BACKOFF = 60000 // 60 seconds

export class ExponentialBackoff {
  private retries = 0
  private readonly maxRetries: number
  private readonly initialDelay: number
  private readonly maxDelay: number
  private readonly randomize: boolean

  constructor(
    options: {
      initialDelay?: number
      maxDelay?: number
      maxRetries?: number
      randomize?: boolean
    } = {},
  ) {
    this.initialDelay = options.initialDelay ?? INITIAL_BACKOFF
    this.maxDelay = options.maxDelay ?? MAX_BACKOFF
    this.maxRetries = options.maxRetries ?? Number.POSITIVE_INFINITY
    this.randomize = options.randomize ?? true
  }

  public next(): number {
    if (this.retries >= this.maxRetries) {
      return -1
    }

    const delay = Math.min(this.initialDelay * 2 ** this.retries, this.maxDelay)

    this.retries++

    if (this.randomize) {
      return delay * (0.5 + Math.random())
    }

    return delay
  }

  public reset(): void {
    this.retries = 0
  }
}
