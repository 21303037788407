import {clsx} from "clsx"
import _ from "lodash"
import React from "react"
import type {TextareaAutosizeProps} from "react-textarea-autosize"
import TextareaAutosize from "react-textarea-autosize"
import scrollerStyles from "~/styles/Scroller.module.css"

const INPUT_CLASSNAME = clsx(
  "w-full resize-none appearance-none rounded-md border border-background-header-secondary bg-background-primary px-3 py-2 text-text-primary focus:ring-2 focus:ring-inset focus:ring-brand-primary",
)

type FieldSetProps = React.HTMLProps<HTMLFieldSetElement> & {
  children: React.ReactNode
  error?: string
  footer?: React.ReactNode
  label?: string
}

const FieldSet = React.forwardRef<HTMLFieldSetElement, FieldSetProps>(({label, children, error, footer}, ref) => (
  <fieldset ref={ref} className="flex flex-col gap-1">
    <div className="flex flex-col">
      {label && <legend className="mb-1 font-medium text-sm text-text-tertiary">{label}</legend>}
      <div className="flex flex-col gap-1">
        {children}
        {error && <span className="text-sm text-status-danger">{error}</span>}
      </div>
    </div>
    {footer}
  </fieldset>
))

FieldSet.displayName = "FieldSet"

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string
  footer?: React.ReactNode
  label: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({error, footer, label, ...props}, ref) => (
  <FieldSet error={error} footer={footer} label={label}>
    <input {...props} aria-invalid={!!error} className={INPUT_CLASSNAME} ref={ref} />
  </FieldSet>
))

Input.displayName = "Input"

export const BaseTextarea = React.forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>((props, ref) => (
  <TextareaAutosize {..._.omit(props, "style")} className={clsx(INPUT_CLASSNAME, scrollerStyles.scroller)} ref={ref} />
))

BaseTextarea.displayName = "BaseTextarea"

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string
  footer?: React.ReactNode
  label: string
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({error, footer, label, ...props}, ref) => (
    <FieldSet error={error} footer={footer} label={label}>
      <BaseTextarea
        {..._.omit(props, "style")}
        aria-invalid={!!error}
        className={clsx(INPUT_CLASSNAME, scrollerStyles.scroller)}
        maxRows={10}
        minRows={2}
        ref={ref}
      />
    </FieldSet>
  ),
)

Textarea.displayName = "Textarea"

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  checkboxLabel?: React.ReactNode
  error?: string
  footer?: React.ReactNode
  label?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({checkboxLabel, error, footer, label, ...props}, ref) => (
    <FieldSet error={error} footer={footer} label={label}>
      <div className="flex items-center gap-1.5">
        <input
          {...props}
          aria-invalid={!!error}
          className="h-4 w-4 rounded-md accent-brand-primary"
          id={`${props.name}-checkbox`}
          ref={ref}
          type="checkbox"
        />
        {checkboxLabel && (
          <label className="text-text-primary" htmlFor={`${props.name}-checkbox`}>
            {checkboxLabel}
          </label>
        )}
      </div>
    </FieldSet>
  ),
)

Checkbox.displayName = "Checkbox"

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  error?: string
  footer?: React.ReactNode
  label: string
  options: Array<{label: string; value: string}>
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({error, footer, label, options, ...props}, ref) => (
    <FieldSet error={error} footer={footer} label={label}>
      <select {...props} aria-invalid={!!error} className={INPUT_CLASSNAME} ref={ref}>
        {options.map((option, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FieldSet>
  ),
)

Select.displayName = "Select"

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string
  footer?: React.ReactNode
  label: string
  options: Array<{label: string; value: string | number}>
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({error, footer, label, options, defaultValue, ...props}, ref) => (
    <FieldSet error={error} footer={footer} label={label}>
      <div className="flex flex-col gap-1.5">
        {options.map((option, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <div className="flex items-center gap-1.5" key={index}>
            <input
              {...props}
              className="h-4 w-4 rounded-md accent-brand-primary"
              defaultChecked={option.value === defaultValue}
              id={`${option.value}-radio`}
              ref={ref}
              type="radio"
              value={option.value}
            />
            <label className="text-text-primary" htmlFor={`${option.value}-radio`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </FieldSet>
  ),
)

Radio.displayName = "Radio"
