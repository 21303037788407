import {ME} from "~/Constants"

export const Endpoints = {
  AUTH_LOGIN: "/auth/login",
  AUTH_LOGIN_MFA_TOTP: "/auth/login/mfa/totp",
  AUTH_LOGOUT: "/auth/logout",
  AUTH_REGISTER: "/auth/register",
  AUTH_SESSIONS: "/auth/sessions",
  AUTH_SESSIONS_LOGOUT: "/auth/sessions/logout",

  ATTACHMENT: (uploadFilename: string) => `/attachments/${encodeURIComponent(uploadFilename)}`,

  CHANNEL: (channelId: string) => `/channels/${channelId}`,
  CHANNEL_ATTACHMENTS: (channelId: string) => `/channels/${channelId}/attachments`,
  CHANNEL_INVITES: (channelId: string) => `/channels/${channelId}/invites`,
  CHANNEL_MESSAGES: (channelId: string) => `/channels/${channelId}/messages`,
  CHANNEL_MESSAGE: (channelId: string, messageId: string) => `/channels/${channelId}/messages/${messageId}`,
  CHANNEL_MESSAGE_ACK: (channelId: string, messageId: string) => `/channels/${channelId}/messages/${messageId}/ack`,
  CHANNEL_MESSAGE_REACTION: (channelId: string, messageId: string, emoji: string) =>
    `/channels/${channelId}/messages/${messageId}/reactions/${emoji}`,
  CHANNEL_MESSAGE_REACTION_QUERY: (channelId: string, messageId: string, emoji: string, query = ME) =>
    `/channels/${channelId}/messages/${messageId}/reactions/${emoji}/${query}`,
  CHANNEL_MESSAGE_REACTIONS: (channelId: string, messageId: string) =>
    `/channels/${channelId}/messages/${messageId}/reactions`,
  CHANNEL_MESSAGES_ACK: (channelId: string) => `/channels/${channelId}/messages/ack`,
  CHANNEL_PIN: (channelId: string, messageId: string) => `/channels/${channelId}/pins/${messageId}`,
  CHANNEL_PINS: (channelId: string) => `/channels/${channelId}/pins`,
  CHANNEL_PINS_ACK: (channelId: string) => `/channels/${channelId}/pins/ack`,
  CHANNEL_TYPING: (channelId: string) => `/channels/${channelId}/typing`,
  CHANNEL_WEBHOOKS: (channelId: string) => `/channels/${channelId}/webhooks`,

  SPACES: "/spaces",
  SPACE: (spaceId: string) => `/spaces/${spaceId}`,
  SPACE_CHANNELS: (spaceId: string) => `/spaces/${spaceId}/channels`,
  SPACE_MEMBER: (spaceId: string, query = ME) => `/spaces/${spaceId}/members/${query}`,
  SPACE_MEMBERS: (spaceId: string) => `/spaces/${spaceId}/members`,
  SPACE_MEMBER_ROLE: (spaceId: string, userId: string, roleId: string) =>
    `/spaces/${spaceId}/members/${userId}/roles/${roleId}`,
  SPACE_BAN: (spaceId: string, userId: string) => `/spaces/${spaceId}/bans/${userId}`,
  SPACE_BANS: (spaceId: string) => `/spaces/${spaceId}/bans`,
  SPACE_ROLE: (spaceId: string, roleId: string) => `/spaces/${spaceId}/roles/${roleId}`,
  SPACE_ROLES: (spaceId: string) => `/spaces/${spaceId}/roles`,
  SPACE_DELETE: (spaceId: string) => `/spaces/${spaceId}/delete`,
  SPACE_EMOJI: (spaceId: string, emojiId: string) => `/spaces/${spaceId}/emojis/${emojiId}`,
  SPACE_EMOJI_USER: (spaceId: string, emojiId: string) => `/spaces/${spaceId}/emojis/${emojiId}/user`,
  SPACE_EMOJIS: (spaceId: string) => `/spaces/${spaceId}/emojis`,
  SPACE_INVITES: (spaceId: string) => `/spaces/${spaceId}/invites`,
  SPACE_VANITY_URL: (spaceId: string) => `/spaces/${spaceId}/vanity-url`,
  SPACE_WEBHOOKS: (spaceId: string) => `/spaces/${spaceId}/webhooks`,

  INVITE: (code: string) => `/invites/${code}`,

  READ_STATES_ACK_BULK: "/read-states/ack-bulk",

  TENOR_FEATURED: "/tenor/featured",
  TENOR_REGISTER_SHARE: "/tenor/register-share",
  TENOR_SEARCH: "/tenor/search",
  TENOR_SUGGEST: "/tenor/suggest",
  TENOR_TRENDING_GIFS: "/tenor/trending-gifs",

  USER_BETA_CODE: (code: string) => `/users/@me/beta-codes/${code}`,
  USER_BETA_CODES: "/users/@me/beta-codes",
  USER_ME: "/users/@me",
  USER_MENTION: (messageId: string) => `/users/@me/mentions/${messageId}`,
  USER_MENTIONS: "/users/@me/mentions",
  USER_MFA_BACKUP_CODES: "/users/@me/mfa/backup-codes",
  USER_MFA_TOTP_DISABLE: "/users/@me/mfa/totp/disable",
  USER_MFA_TOTP_ENABLE: "/users/@me/mfa/totp/enable",
  USER_NOTE: (userId: string) => `/users/@me/notes/${userId}`,
  USER_PROFILE: (query = ME) => `/users/${query}/profile`,
  USER_SAVED_MESSAGE: (messageId: string) => `/users/@me/saved-messages/${messageId}`,
  USER_SAVED_MESSAGES: "/users/@me/saved-messages",
  USER_SETTINGS: "/users/@me/settings",
  USER_SPACES: (spaceId: string) => `/users/@me/spaces/${spaceId}`,
  USER_DOMAIN_CHALLENGE: "/users/@me/domain-challenge",
  USER_DOMAIN_VERIFY: "/users/@me/domain-verify",

  WEBHOOK: (webhookId: string) => `/webhooks/${webhookId}`,
} as const
