import {Endpoints} from "~/Endpoints"
import Dispatcher from "~/flux/Dispatcher"
import type {HttpResponse} from "~/lib/HttpClient"
import * as HttpClient from "~/lib/HttpClient"

export type RegisterData = {
  email: string
  username: string
  nickname: string
  password: string
  beta_code: string
}

export const login = async (email: string, password: string) => {
  const {body} = await HttpClient.post<
    {mfa: false; totp: false; token: string} | {mfa: true; totp: true; ticket: string}
  >({url: Endpoints.AUTH_LOGIN, body: {email, password}})
  return body
}

export const loginMfaTotp = async (code: string, ticket: string) => {
  const {body} = await HttpClient.post<{token: string}>({
    url: Endpoints.AUTH_LOGIN_MFA_TOTP,
    body: {code, ticket},
  })
  return body
}

export const register = async (data: RegisterData) => {
  const {body} = await HttpClient.post<{token: string}>({url: Endpoints.AUTH_REGISTER, body: data})
  return body
}

const doLogout = () => {
  Dispatcher.dispatch({type: "MODAL_POP_ALL"})
  Dispatcher.dispatch({type: "LOGOUT"})
}

export const logout = async () =>
  HttpClient.post({url: Endpoints.AUTH_LOGOUT})
    .then(doLogout)
    .catch((error: HttpResponse) => {
      if (error.status === 401) {
        doLogout()
      } else {
        throw error
      }
    })
